<template>
  <MiscellaneousCountry dialog-name="Countries" />
</template>
<script>
import MiscellaneousCountry from '@/views/app/miscellaneous/Country'

export default {
  name: 'CountryWrapper',
  components: {
    MiscellaneousCountry
  }
}
</script>
